import { ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Event as NavigationEvent, NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { DateTime } from 'luxon';
import { Observable, Subject, take, takeUntil, tap } from 'rxjs';

import { OktaInterfaceService, TokenError } from '@shure/cloud/shared/okta/data-access';
import { PermissionsService } from '@shure/cloud/shared/permissions/feature-permissions';
import { BreakpointService } from '@shure/cloud/shared/services/media-breakpoints';
import { OrganizationsStoreService } from '@shure/cloud/shared/services/organizations-store-service';
import {
	CloseTextOption,
	InfoDialogComponent,
	SecnavLayoutStateFacade,
	SecondaryNavList,
	SecondaryNavService,
	ShIgniteDialogBoxService,
	ShProgressiveProfileDialogComponent,
	SidenavLayoutStateFacade,
	SnackbarService,
	ToggleSecondayNavList
} from '@shure/cloud/shared/ui/components';
import { AccountInfoDto, ApiUsersService } from '@shure/cloud/shared/users/data-access';
import { AnalyticsEvents, AnalyticsService } from '@shure/cloud/shared/utils/analytics';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { ILogger } from '@shure/shared/angular/utils/logging';

import {
	departmentList,
	industryOptions,
	interestsOptions,
	MaintenanceResponse,
	ProgProfileDataResponse,
	ProgProfileFormDataDetails,
	relationshipOptions
} from './app-shell.model';

@Component({
	selector: 'sh-app-shell',
	styleUrls: ['./app-shell.component.scss'],
	templateUrl: 'app-shell.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppShellComponent implements OnInit, OnDestroy {
	public isOpen$: Observable<boolean> | undefined;
	public isSecOpen$: Observable<boolean> | undefined;
	private destroy$: Subject<void> = new Subject<void>();
	public isMyAccount!: boolean;
	public appVersion!: string;
	public startDate!: string;
	public endDate!: string;
	public maintenanceData!: MaintenanceResponse | null;
	public showMaintenanceBanner!: boolean | undefined;
	public routeAccount$: Subject<boolean> = new Subject<boolean>();
	public showFeatureRequestButton!: boolean;
	public readonly isSmallDevice = this.breakPointService.isLteXSmall;
	public isAccount!: boolean;
	public defaultOrgId!: string;
	public toggleSecondaryNav$!: Observable<string>;
	public getSecondaryNavListItems$!: Observable<SecondaryNavList[]>;
	public toggleSecondayNavList = ToggleSecondayNavList;
	public relationShipOptions = relationshipOptions;
	public interestsOptions = interestsOptions;
	public departmentList = departmentList;
	public industryOptions = industryOptions;
	public progressiveProfileFormData: ProgProfileFormDataDetails[] = [
		{
			label: this.translocoService.translate('cloud.shared.labels.mobile-phone'),
			name: 'mobilePhone',
			validators: [],
			type: 'text',
			token: '1'
		},
		{
			label: this.translocoService.translate('cloud.shared.labels.work-phone'),
			name: 'workPhone',
			validators: [],
			type: 'text',
			token: '1'
		},
		{
			label: this.translocoService.translate('cloud.shared.labels.interests'),
			name: 'interests',
			validators: [],
			type: 'checkbox',
			token: '1'
		},
		{
			label: '',
			name: 'tc',
			validators: [Validators.requiredTrue],
			type: 'checkbox',
			token: '1'
		},
		{
			label: this.translocoService.translate('cloud.shared.labels.industry'),
			name: 'segment',
			validators: [],
			type: 'select',
			token: '2'
		},
		{
			label: this.translocoService.translate('cloud.shared.labels.industry-details'),
			name: 'segmentDetails',
			validators: [Validators.required],
			disabled: true,
			type: 'select',
			token: '2'
		},
		{
			label: this.translocoService.translate('cloud.shared.labels.job-title'),
			name: 'jobTitle',
			validators: [],
			errorLabel: this.translocoService.translate('cloud.shared.labels.job-title'),
			type: 'text',
			token: '3'
		},
		{
			label: this.translocoService.translate('cloud.shared.labels.department'),
			name: 'department',
			validators: [],
			type: 'select',
			token: '3'
		},
		{
			label: this.translocoService.translate('cloud.shared.labels.your-relationship-to-shure'),
			name: 'relationToShure',
			validators: [],
			type: 'checkbox',
			token: '3'
		}
	];
	public showNotifications = false;
	public showNotificationPreferences = false;

	constructor(
		private layoutStateFacade: SidenavLayoutStateFacade,
		private secStateFacade: SecnavLayoutStateFacade,
		private router: Router,
		private organizationStore: OrganizationsStoreService,
		private readonly translocoService: TranslocoService,
		private readonly oktaService: OktaInterfaceService,
		private readonly dialog: MatDialog,
		public permissionsService: PermissionsService,
		private breakPointService: BreakpointService,
		private oktaIntfService: OktaInterfaceService,
		private secondaryNavService: SecondaryNavService,
		private analyticsService: AnalyticsService,
		@Inject(APP_ENVIRONMENT) public appEnv: AppEnvironment,
		private apiUsersService: ApiUsersService,
		private snackBarService: SnackbarService,
		private logger: ILogger,
		public shDialogService: ShIgniteDialogBoxService
	) {}

	@HostListener('window:keydown', ['$event'])
	public handleKeyboardEvent(event: KeyboardEvent): void {
		if (event.shiftKey && event.key === 'D') {
			this.showNotifications = !this.showNotifications;
			this.showNotificationPreferences = !this.showNotificationPreferences;
		}
	}

	public ngOnInit(): void {
		this.isOpen$ = this.layoutStateFacade.isOpen$;
		this.isSecOpen$ = this.secStateFacade.isOpen$;
		this.toggleSecondaryNav$ = this.secondaryNavService.getToggleSecondaryNav$();
		this.getSecondaryNavListItems$ = this.secondaryNavService.getSecondaryNavListItemsObs$();
		this.oktaIntfService
			.getDefaultOrgId$()
			.pipe(takeUntil(this.destroy$))
			.subscribe((val: string) => {
				this.defaultOrgId = val;
			});
		const accountPaths = window.location.pathname.split('/');
		this.isMyAccount = accountPaths[1] === 'account' ? true : false;
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const accountPaths = event.url.split('/');
				this.isMyAccount = accountPaths[1] === 'account' ? true : false;
			}
			this.checkNavigationErrors();
		});

		this.organizationStore
			.getVersion()
			.pipe(takeUntil(this.destroy$))
			.subscribe((val: string) => {
				this.appVersion = val;
			});
		this.organizationStore
			.getMaintenance()
			.pipe(takeUntil(this.destroy$))
			.subscribe((val) => {
				this.maintenanceData = val;
				this.showMaintenanceBanner = this.maintenanceData?.banner;
				this.startDate = this.maintenanceData?.startAt
					? this.convertToUserTimeZone(this.maintenanceData?.startAt)
					: '--';
				this.endDate = this.maintenanceData?.endAt
					? this.convertToUserTimeZone(this.maintenanceData?.endAt)
					: '--';
			});

		this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEvent) => {
			if (event instanceof NavigationEnd) {
				this.setBackgroundColor(event.url);
			}
		});
		this.setBackgroundColor(this.router.url);
		if (
			localStorage.getItem('progressiveDialogDisplayed') === 'true' ||
			localStorage.getItem('isTcAccepted') !== 'true'
		) {
			this.enableProgressiveProfile();
			localStorage.setItem('progressiveDialogDisplayed', 'false');
		}
	}

	/*
	 * User account data is fetched for progressive profiling.
	 * Methods for formatting data and opening dialog are triggered.
	 * This method is triggered for one time after login.
	 */
	public enableProgressiveProfile(): void {
		this.apiUsersService
			.getAccountInfo$Response()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (accountInfoResponse) => {
					const accountInfoDetailsBody = JSON.parse(<string>(<unknown>accountInfoResponse.body));
					if (accountInfoDetailsBody?.body) {
						const accountInfoDetails = accountInfoDetailsBody.body;
						localStorage.setItem('isTcAccepted', accountInfoDetails?.tc?.isTcAccepted);
						if (accountInfoDetails.segment && !accountInfoDetails.segmentDetails) {
							this.setIndustryDetailsProgProfile(accountInfoDetails.segment);
						}
						const filteredProgProfileFormData = this.filterProgProfileFormData(
							this.progressiveProfileFormData,
							accountInfoDetails
						);
						if (filteredProgProfileFormData.length !== 0) {
							this.openProgressiveProfileDialog(filteredProgProfileFormData, accountInfoDetails);
						}
					}
				}
			});
	}

	/**
	 * sets industry details available for progressive profile form data if
	 * industry is already selected by the user
	 * @param industry Industry previously selected by the user
	 */
	public setIndustryDetailsProgProfile(industry: string): void {
		const selectedIndustryOption = industryOptions.find((industryItem) => industryItem.value === industry);
		const index = this.progressiveProfileFormData?.findIndex((formRow) => formRow.name === 'segmentDetails');
		this.progressiveProfileFormData[index].data = selectedIndustryOption?.industryDetail;
		this.progressiveProfileFormData[index].disabled = false;
	}

	/*
	 * returns filtered formData to be displayed in the dialog
	 */
	public filterProgProfileFormData(
		progressiveProfileFormData: ProgProfileFormDataDetails[],
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		accountInfoDetails: any
	): ProgProfileFormDataDetails[] {
		const profileFilteredFormFirst: ProgProfileFormDataDetails[] = [];
		const profileFilteredFormSecond: ProgProfileFormDataDetails[] = [];
		const profileFilteredFormThird: ProgProfileFormDataDetails[] = [];
		if (!this.defaultOrgId) {
			progressiveProfileFormData[1].token = '';
			progressiveProfileFormData[8].token = '1';
		}
		const isTandCAccepted = accountInfoDetails?.tc?.isTcAccepted;
		progressiveProfileFormData.forEach((formDetail: ProgProfileFormDataDetails) => {
			if (!accountInfoDetails[formDetail.name] || (formDetail.name === 'tc' && !isTandCAccepted)) {
				if (formDetail.token === '1' && (formDetail.name !== 'tc' || !isTandCAccepted)) {
					profileFilteredFormFirst.push(formDetail);
					this.moveTermsFieldtoLast(profileFilteredFormFirst);
				} else if (formDetail.token === '2' && profileFilteredFormFirst.length === 0 && this.defaultOrgId) {
					formDetail.name === 'segment'
						? (formDetail.data = this.industryOptions)
						: (formDetail.name = 'segments');
					profileFilteredFormSecond.push(formDetail);
				} else if (formDetail.token === '3' && profileFilteredFormSecond.length === 0 && this.defaultOrgId) {
					if (formDetail.name === 'department') {
						formDetail.data = this.departmentList;
						formDetail.name = 'departments';
					}
					profileFilteredFormThird.push(formDetail);
				}
			}
		});

		for (const profileFilteredFormData of [
			profileFilteredFormFirst,
			profileFilteredFormSecond,
			profileFilteredFormThird
		]) {
			if (profileFilteredFormData.length !== 0) {
				return profileFilteredFormData;
			}
		}
		return [];
	}

	/**
	 * Moves the form field with the name 'tc' to the last position in the given profile form array.
	 * @param profileFilteredFormFirst - An array of ProgProfileFormDataDetails objects that represents profile forms.
	 */
	public moveTermsFieldtoLast(profileFilteredFormFirst: ProgProfileFormDataDetails[]): void {
		const tcFormIndex = profileFilteredFormFirst.findIndex((profileForm) => profileForm.name === 'tc');

		if (tcFormIndex !== -1) {
			// Remove the form from its current position and store it
			const tcForm = profileFilteredFormFirst.splice(tcFormIndex, 1);
			profileFilteredFormFirst.push(...tcForm);
		}
	}

	/*
	 * Opens progressive profile dialog and sends data for updating details
	 */
	public openProgressiveProfileDialog(
		filteredProgProfileFormData: ProgProfileFormDataDetails[],
		accountInfoDetails: AccountInfoDto
	): void {
		const isTandCAccepted = accountInfoDetails.tc?.isTcAccepted;
		this.dialog
			.open(ShProgressiveProfileDialogComponent, {
				width: '452px',
				data: filteredProgProfileFormData,
				panelClass: 'sh-custom-dialog-container',
				disableClose: !isTandCAccepted ? true : false
			})
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: ProgProfileDataResponse) => {
				if (result) {
					const updateAccountData = result.data ? result.data : {};
					if (
						!Object.values(updateAccountData).every(
							(value: string[] | string) => value.length === 0 || value === ''
						)
					) {
						this.updateAccountInfo(updateAccountData);
					}
				}
			});
	}

	/**
	 * Sends a request to update the user's account information with the provided data.
	 * @param payLoad - AccountInfoDto representation containing the account information to update.
	 */
	public updateAccountInfo(payLoad: AccountInfoDto): void {
		this.apiUsersService
			.accountInfo$Response({ body: payLoad })
			?.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (updateProfileResponse) => {
					if (payLoad.tc?.isTcAccepted) {
						localStorage.setItem('isTcAccepted', 'true');
					}
					this.logger.debug('updateAccountInfo', 'update account info method');
					const updateProfileData = JSON.parse(<string>(<unknown>updateProfileResponse.body));
					this.snackBarService.open(
						this.translocoService.translate(
							updateProfileData.body.messageKey
								? 'cloud.shared.labels.' + updateProfileData.body.messageKey
								: updateProfileData.body.message
						),
						CloseTextOption.Ok
					);
				},
				error: (error) => {
					this.logger.error('updateAccountInfo', 'update account info error', { error });
					const errorResponse = JSON.parse(error.error);
					this.snackBarService.open(
						this.translocoService.translate(
							errorResponse.i18nKey
								? 'cloud.shared.error-labels.' + errorResponse.i18nKey
								: errorResponse.message
						),
						CloseTextOption.Ok
					);
				}
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public openSidenav(): void {
		this.layoutStateFacade.open();
	}

	/**
	 * function called when user signs out
	 */
	public onSignOut(): void {
		this.analyticsService.analyticDataCollector(AnalyticsEvents.SignOut, {});
	}

	/**
	 * function called when user clicks contact us
	 */
	public onContactUs(): void {
		/* eslint-disable @typescript-eslint/naming-convention */
		this.analyticsService.analyticDataCollector(AnalyticsEvents.HelpRequested, {
			request_type: 'contact_us'
		});
	}

	public toggleSecnav(): void {
		this.isSecOpen$
			?.pipe(
				take(1),
				tap((toggleSideNav) => (toggleSideNav ? this.secStateFacade.close() : this.secStateFacade.open())),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	public navigateToLicenses(): void {
		this.secondaryNavService.setActiveListItem('');
		this.secondaryNavService
			.getActiveListItem$()
			?.pipe(
				tap((activeListitem: string) => {
					if (activeListitem === 'organization') {
						return;
					}
					if (activeListitem) {
						this.router.navigate([`licenses/${activeListitem}/license-groups-list`]);
					} else {
						this.router.navigate(['licenses']);
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	/**
	 * Handle date time conversion to user time zone
	 */
	public convertToUserTimeZone(utcTime: string): string {
		const userTime = DateTime.fromISO(utcTime, { zone: 'utc' })
			.setZone('local')
			.toFormat('yyyy-MM-dd hh:mm:ss a ZZZZ');
		return userTime;
	}

	public setBackgroundColor(url: string): void {
		if (url === '/account') {
			this.isAccount = true;
			this.routeAccount$.next(true);
		} else {
			this.isAccount = false;
			this.routeAccount$.next(false);
		}
	}

	public checkNavigationErrors(): void {
		// this.router.getCurrentNavigation().extras.state somehow gets cleared before reaching this code
		const error = history.state?.error;
		if (error === undefined) {
			return;
		}

		const baseData = {
			okLabel: this.translocoService.translate('cloud.shared.button-text.ok'),
			showWarningIcon: false
		};
		let data = {};
		if (error === TokenError.MissingRole) {
			data = {
				title: this.translocoService.translate('cloud.shared.error-labels.login-errors.missing-role.title'),
				body: [this.translocoService.translate('cloud.shared.error-labels.login-errors.missing-role.body')]
			};
		}
		// else if (error === TokenError.MissingTenant) {
		// 	const link = `<a class="sh-info-dialog-a" href="https://p.shure.com/shurecloud-ga-notice">${this.translocoService.translate(
		// 		'cloud.shared.error-labels.login-errors.missing-tenant.link'
		// 	)}</a>`;
		// 	data = {
		// 		title: this.translocoService.translate('cloud.shared.error-labels.login-errors.missing-tenant.title'),
		// 		body: [
		// 			this.translocoService.translate('cloud.shared.error-labels.login-errors.missing-tenant.body.line1'),
		// 			this.translocoService
		// 				.translate('cloud.shared.error-labels.login-errors.missing-tenant.body.line2')
		// 				.replace('{link}', link)
		// 		]
		// 	};
		// }
		else {
			// If some other error is passed in
			data = {
				title: this.translocoService.translate('cloud.shared.error-labels.login-errors.unknown.title'),
				body: [this.translocoService.translate('cloud.shared.error-labels.login-errors.unknown.body')]
			};
		}
		this.dialog
			.open(InfoDialogComponent, {
				panelClass: 'sh-custom-dialog-container',
				width: '450px',
				data: {
					...baseData,
					...data
				},
				disableClose: true
			})
			.beforeClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.oktaService.signOut();
			});
	}
}
