// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/* eslint-disable @typescript-eslint/naming-convention */
import { AppEnvironment } from '@shure/cloud/shared/utils/config';
import { LogLevel } from '@shure/shared/angular/utils/logging';

export const environment: AppEnvironment = {
	production: false,
	remoteLogging: false,
	sessionInactivity: {
		idleWarnTime: 10 * 60,
		idleGracePeriod: 5 * 60
	},
	authorization: {
		clientId: '0oa27li28xcV0NXwU0h8',
		oktaDomain: 'iam.dev.shure.services',
		oktaDomainExt: 'accountssb.shure.com',
		authServerPath: '/oauth2/default',
		scopes: ['openid', 'profile', 'email', 'offline_access', 'shure_tenant'],
		enableSecondaryIDP: false,
		secondaryIDP: '0oa11drlvpkrHCQQK0h8'
	},
	useClassicEngine: false,
	orgs: {
		groupsApiUrl: 'https://orgs.dev2.shure.services/groups',
		licensesApiUrl: 'https://orgs.dev2.shure.services/licenses',
		orgsApiUrl: 'https://orgs.dev2.shure.services/organizations',
		rolesApiUrl: 'https://orgs.dev2.shure.services/roles',
		usersApiUrl: 'https://orgs.dev2.shure.services/users',
		userGroupsApiUrl: 'https://orgs.dev2.shure.services/user-groups',
		pemissionsApiUrl: 'https://orgs.dev2.shure.services/permissions',
		notificationsApiUrl: 'https://notifications-api.dev2.shure.services/api/v1',
		notificationPreferencesApiUrl: 'https://notifications-api.dev2.shure.services/api',
		notificationsWebSocketUrl: 'wss://notifications-api-sockets.dev2.shure.services',
		preferencesApiUrl: 'https://preferences-api.dev2.shure.services/api/v1',
		maintenanceApiUrl: 'https://orgs-maintenance.dev2.shure.services/maintenance',
		featureRequestApiUrl: 'https://orgs.dev2.shure.services/feature-requests',
		eventLogRequestApiUrl: 'https://orgs.dev2.shure.services/activity-logs'
	},
	sysApi: {
		url: 'https://system-api.dev2.shure.services/graphql',
		apiKey: ''
	},
	appUrls: {
		deviceUrl: 'https://devices.dev2.shure.services',
		deviceExtUrl: 'https://devices.dev2.cloud.shure.com',
		rfscanUrl: 'https://scans.dev2.shure.services/home',
		rfscanExtUrl: 'https://scans.dev2.shure.com/home'
	},
	featureFlags: {
		// TODO: in ORG-3342 below line code will be modified and follow naming convention
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'event-log': true,
		changeOrganization: true,
		verifiedOrganizationLicenses: true,
		enableAnx4ProductType: true,
		enableAdpsmProductType: true,
		deviceFeatures: {
			ANX4: {
				activateEndPoints: true,
				decommission: false,
				moveLicenses: true
			},
			ADTD: {
				activateEndPoints: true,
				decommission: false,
				moveLicenses: true
			},
			ADTQ: {
				activateEndPoints: true,
				decommission: false,
				moveLicenses: true
			},
			'IMX-Room': {
				activateEndPoints: true,
				decommission: true,
				moveLicenses: false
			}
		}
	},
	signInMethod: 'okta-hosted',
	signOutScope: 'signout-okta',

	logLevel: LogLevel.Debug,
	appVersion: '1.0.0.0',
	clientVersion: 'X.X.X.X',
	showOnlyIdp: false,
	isDebugModeEnabled: true,
	enableMixPanel: true,
	mixpanelProjectToken: 'fa32bc1c4ef8602fae1df268bcfa9d93',
	appType: 'cloud',
	appRoles: ['organization-admin', 'device-admin', 'user-license-and-device-admin', 'license-admin'],
	application: 'organization-portal'
};
