import { ChangeDetectionStrategy, Component, computed, Inject, input } from '@angular/core';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import { OrganizationsStoreService } from '@shure/cloud/shared/services/organizations-store-service';
import { generateColorFromName } from '@shure/cloud/shared/ui/components';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';

import { SwitchOrganizationService } from './services/switch-organization.service';

@Component({
	selector: 'sh-switch-organization-main',
	templateUrl: './switch-organization-main.component.html',
	styleUrls: ['./switch-organization-main.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchOrganizationMainComponent {
	public contentOrientation = input<'rowLayout' | 'columnLayout'>('columnLayout');

	public accountInformationSignal = this.organizationsStore.accountInformation;

	public initials = computed(() => {
		if (this.organizationName() === '') return '';
		return (
			this.organizationName().split(' ')[0].charAt(0).toUpperCase() +
			(this.organizationName().split(' ')[1]?.charAt(0).toUpperCase() || '')
		);
	});

	public colorIndex = computed(() => {
		return generateColorFromName(this.organizationName());
	});

	public organizationName = computed(() => {
		const orgName = this.accountInformationSignal()?.organizationDetails?.organizationName ?? '';
		return orgName;
	});

	constructor(
		private switchOrganizationService: SwitchOrganizationService,
		private readonly oktaIntfService: OktaInterfaceService,
		private organizationsStore: OrganizationsStoreService,
		@Inject(APP_ENVIRONMENT) public appEnv: AppEnvironment
	) {}

	public openChangeOrganization(): void {
		this.switchOrganizationService.openChangeDialog();
	}
}
